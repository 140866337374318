<template>
  <div class=container>
      <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2">Gallery of Separation Results</font></div></td>
          </tr>
          <tr>
            <td>Here, we show several examples of our separation results. In each case, we mention the separation method we have used. In all cases, the scene image is the sum of the computed direct and global images. However, to bring out the details, for some of the scenes we have brightened the direct and global images by a scale factor (between 1 and 2) with respect to the scene image. <strong>By clicking on each of the thumbnail images shown below, you can see the corresponding high resolution image.</strong><br>
            </td>
            
          </tr>
            <router-link :to="{path:'/projects/categories/project', query:{cid:'Computational Imaging',pid:'Fast Separation of Direct and Global Images'}}">(Back to Main Project Page) </router-link>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="47%"><strong>Blonde Hair: Bidirectional Texture Function</strong></td>
                        <td width="53%"><div align="right"><strong>Method : Checkerboard<br>
                        </strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="32%"><div align="center"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image5751.jpg" width="202" height="151"><br>
                        </div></td>
                        <td valign="top"><div align="center">
                          <div v:shape="_x0000_s1296">
                            <p align="left">This  video shows the separation results for blonde hair as a function of  light source direction (varied from -30 degrees to +30 degrees in steps  of 3&nbsp; degrees, where 0 degrees corresponding to the viewing direction).  While the global image varies slowly with respect to lighting  direction, the direct component changes more drastically.&nbsp; <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Blonde-Hair_640x480.mpg">MPEG</a>, <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Blonde-Hair_640x480.mov">Quicktime</a></p>
                          </div>
                        </div>                          <div align="left"></div><div align="center"></div></td>
                      </tr>
                    </tbody></table>
                      <table width="100%" border="0" cellspacing="10" cellpadding="0">

                        <tbody><tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>

          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td width="47%"><strong>Pebbles : Bidirectional Texture Function</strong></td>
                      <td width="53%"><div align="right"><strong>Method : Checkerboard<br>
                      </strong></div></td>
                    </tr>
                  </tbody></table>
                    <div align="right"></div></td>
              </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td width="32%"><div align="center"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image6231.jpg" width="202" height="153"><br>
                      </div></td>
                      <td valign="top"><div align="center">
                          <div v:shape="_x0000_s1296">
                            <div v:shape="_x0000_s1303">
                              <p align="left">This  video shows the separation results for the pebbles sample as a function  of lighting direction (varied from -30 degrees to +30 degrees in steps  of 3 degrees, where 0 degrees corresponding to the viewing direction).  As the source direction varies, the direct image varies significantly  within local regions as the shadows shift and the shading changes.  However, the global image varies more smoothly with respect to the  lighting direction. <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Pebbles_640x480.mpg">MPEG</a>, <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Pebbles_640x480.mov">Quicktime</a></p>
                            </div>
                            </div>
                      </div>
                          <div align="left"></div>
                        <div align="center"></div></td>
                    </tr>
                  </tbody></table>
                    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td>&nbsp;</td>
                      </tr>
                  </tbody></table></td>
              </tr>
            </tbody></table></td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </tbody></table>

  </div>
</template>

<script>
export default {
}
</script>

<style>
.table{
  margin:auto
}
</style>